import * as React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Map from 'ol/Map';
import View from 'ol/View';
import classNames from 'classnames';
import { LAYERS } from 'shared/src/constants/layers';
import Zoom from 'ol/control/Zoom';
import { withRouter } from 'react-router';
import { defaults as interactionDefaults } from 'ol/interaction';
import MousePosition from 'ol/control/MousePosition';
import { createStringXY } from 'ol/coordinate';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4.js';
import LayerSwitcher from 'ol-ext/control/LayerSwitcher';
import Bluebird from 'bluebird';
import BaseLayer from './BaseLayer';
import StreetLayer from './StreetLayer';
import RadarLayer from './RadarLayer';
import CountryLayer from './CountryLayer';
import ProductLayer from './ProductLayer';
import PlotLayer from './PlotLayer';
import MeparLayer from './MeparLayer';
import BlockPlotLayer from './BlockPlotLayer';
import BlockPlotProductLayer from './BlockPlotProductLayer';
import SamplingLayer from './SamplingLayer';
import KarnaviPhotosLayer from './KarnaviPhotosLayer';
import DrawingLayer from './DrawingLayer';
import CropLayer from './CropLayer';
import CityLayer from './CityLayer';
import PositionLayer from './PositionLayer';
import WoodwiserDTMLayer from './WoodwiserDTMLayer';
import WoodwiserForestUnitLayer from './WoodwiserForestUnitLayer';
import { ROLE } from 'shared/src/constants/role';
import WoodwiserHeightLayer from './WoodwiserHeightLayer';
import WoodwiserAspectLayer from './WoodwiserAspectLayer';
import WoodwiserSlopeLayer from './WoodwiserSlopeLayer';
import WoodwiserRGBLayer from './WoodwiserRGBLayer';
import WoodwiserStemLayer from './WoodwiserStemLayer';
import WoodwiserCrownLayer from './WoodwiserCrownLayer';
import SamplingZoneVectorLayer from './SamplingZoneVectorLayer';
import ProkatSoilZoneGeneticLayer from './ProkatSoilZoneGeneticLayer';
import ProkatSoilZonePhysicalLayer from './ProkatSoilZonePhysicalLayer';
import ProkatSoilSampleLayer from './ProkatSoilSampleLayer';
import ProkatSoilSampleMeparLayer from './ProkatSoilSampleMeparLayer';
import EKLayer from './EKLayer';
import EKProductLayer from './EKProductLayer';
import ProkatDTMLayer from './ProkatDTMLayer';
import ProkatNDVILayer from './ProkatNDVILayer';
import ProkatLegend from './ProkatLegend';
import ProkatSoilManagementZoneLayer from './ProkatSoilManagementZoneLayer';
import BlockPlotSoilManagementZoneLayer from './BlockPlotSoilManagementZoneLayer';
import CemeteryUAVLayer from './CemeteryUAVLayer';
import CemeteryGraveLayer from './CemeteryGraveLayer';
import CemeteryObjectLayer from './CemeteryObjectLayer';
import CemeteryLegend from './CemetryLegend';
import DamageChronologyLayer from './DamageChronologyLayer';
import YieldsLayer from './YieldsLayer';

const styles = (theme) => ({
    map: {
        height: '100%',
        width: '100%',
    },
});

proj4.defs(
    'EPSG:23700',
    '+proj=somerc +lat_0=47.14439372222222 +lon_0=19.04857177777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=52.17,-71.82,-14.9,0,0,0,0 +units=m +no_defs'
);
// '+proj=somerc +lat_0=47.14439372222222 +lon_0=19.04857177777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +units=m +no_defs +towgs84=52.684,-71.194,-13.975,-0.312,-0.1063,-0.3729,1.0191'

register(proj4);

class MyMap extends React.Component {
    map = null;
    layers = [];

    async componentDidUpdate(prevProps) {
        if (this.props.layers !== prevProps.layers) {
            await this.updateLayers(this.props.layers);
        }

        if (this.props.infoPanelState !== prevProps.infoPanelState) {
            this.map.updateSize();
        }

        if (this.props.drawing !== prevProps.drawing && this.props.manageDrawings) {
            const drawingLayer = this.getLayer(LAYERS.TYPES.DRAWING);

            if (drawingLayer.layer.get('visible')) {
                drawingLayer.updateDrawing(this.props.drawing);
            }
            if (this.props.drawing) {
                this.getLayer(LAYERS.TYPES.PLOT).removeInteractions();
            } else {
                this.getLayer(LAYERS.TYPES.PLOT).addInteractions();
            }
        }

        if (this.props.manageSamplings !== prevProps.manageSamplings) {
            if (this.props.manageSamplings) {
                this.getLayer(LAYERS.TYPES.PLOT).removeInteractions();
            } else {
                this.getLayer(LAYERS.TYPES.PLOT).addInteractions();
            }
        }

        if (this.props.selectedDeads !== prevProps.selectedDeads) {
            this.getLayer(LAYERS.TYPES.CEMETERY_GRAVE).setSelectedDeads(this.props.selectedDeads);
        }

        if (this.props.selectedGraves !== prevProps.selectedGraves) {
            this.getLayer(LAYERS.TYPES.CEMETERY_GRAVE).setSelectedGraves(this.props.selectedGraves);
        }
    }

    async updateLayers(layers) {
        return Bluebird.each(layers, async (layer) => {
            const mapLayer = this.getLayer(layer.type);
            return mapLayer && mapLayer.update(layer);
        });
    }

    getLayer(type) {
        return this.layers.find((mapLayer) => {
            if (Array.isArray(mapLayer.getLayer())) {
                return mapLayer.getLayer()[0].get('type') === type;
            } else {
                return mapLayer.getLayer().get('type') === type;
            }
        });
    }

    async componentDidMount() {
        const interactions = interactionDefaults({ altShiftDragRotate: false, pinchRotate: false });

        this.map = new Map({
            target: 'map',
            units: 'm',
            //tileSize: new Size(512, 512),
            //maxExtent: new Extent(426000, 43000, 938000, 363000),
            interactions,
            controls: [
                new Zoom(),
                new MousePosition({
                    coordinateFormat: createStringXY(0),
                    projection: 'EPSG:23700',
                    undefinedHTML: '&nbsp;',
                }),
                new MousePosition({
                    coordinateFormat: createStringXY(6),
                    projection: 'EPSG:4326',
                    undefinedHTML: '&nbsp;',
                    className: 'wgs84-mouse-position',
                }),
                new LayerSwitcher({
                    reordering: false,
                }),
            ],
            view: new View({
                projection: 'EPSG:23700',
            }),
        });

        this.layers.push(new CountryLayer(this));

        if (
            this.props.user.role !== ROLE.CEMETERY &&
            this.props.user.role !== ROLE.CEMETERY_PUBLIC
        ) {
            this.layers.push(new BaseLayer(this));
            this.layers.push(new CityLayer(this));
            this.layers.push(new StreetLayer(this));
        } else {
            this.layers.push(new StreetLayer(this));
        }

        if (
            this.props.user.role !== ROLE.FARMER &&
            this.props.user.role !== ROLE.WOODWISER &&
            this.props.user.role !== ROLE.PROKAT &&
            this.props.user.role !== ROLE.AGRARMONITOR &&
            this.props.user.role !== ROLE.AGRO &&
            this.props.user.role !== ROLE.CEMETERY &&
            this.props.user.role !== ROLE.CEMETERY_PUBLIC
        ) {
            this.layers.push(new RadarLayer(this));
            this.layers.push(new CropLayer(this));
            this.layers.push(new KarnaviPhotosLayer(this));
        }

        if (this.props.user.role === ROLE.ADMIN) {
            this.layers.push(new DamageChronologyLayer(this));
        }

        if (!window.positionLayer) {
            window.positionLayerVisible = false;
            window.positionLayer = new PositionLayer(this);
        }

        if (
            this.props.user.role !== ROLE.WOODWISER &&
            this.props.user.role !== ROLE.PROKAT &&
            this.props.user.role !== ROLE.AGRARMONITOR &&
            this.props.user.role !== ROLE.AGRO &&
            this.props.user.role !== ROLE.CEMETERY &&
            this.props.user.role !== ROLE.CEMETERY_PUBLIC
        ) {
            this.layers.push(new ProductLayer(this, this.props.user));
            this.layers.push(new PlotLayer(this));
            this.layers.push(new SamplingZoneVectorLayer(this));
            this.layers.push(new SamplingLayer(this));
            this.layers.push(new DrawingLayer(this));
            this.layers.push(new YieldsLayer(this));
            this.layers.push(window.positionLayer);
        }

        if (this.props.user.role === ROLE.WOODWISER) {
            this.layers.push(new WoodwiserRGBLayer(this));
            this.layers.push(new WoodwiserDTMLayer(this));
            this.layers.push(new WoodwiserHeightLayer(this));
            this.layers.push(new WoodwiserAspectLayer(this));
            this.layers.push(new WoodwiserSlopeLayer(this));
            this.layers.push(new WoodwiserForestUnitLayer(this));
            this.layers.push(new WoodwiserStemLayer(this));
            this.layers.push(new WoodwiserCrownLayer(this));
        }

        if (this.props.user.role === ROLE.PROKAT) {
            this.layers.push(new ProkatDTMLayer(this));
            this.layers.push(new ProkatNDVILayer(this));
            this.layers.push(new ProkatSoilSampleMeparLayer(this));
            this.layers.push(new ProkatSoilZoneGeneticLayer(this));
            this.layers.push(new ProkatSoilZonePhysicalLayer(this));
            this.layers.push(new ProkatSoilManagementZoneLayer(this));
            this.layers.push(new ProkatSoilSampleLayer(this));
        }

        if (this.props.user.role === ROLE.AGRARMONITOR) {
            this.layers.push(new EKProductLayer(this));
            this.layers.push(new EKLayer(this));
        }

        if (this.props.user.role === ROLE.AGRO) {
            this.layers.push(new BlockPlotProductLayer(this));
            this.layers.push(new BlockPlotSoilManagementZoneLayer(this));
            this.layers.push(new MeparLayer(this));
            this.layers.push(new BlockPlotLayer(this));
        }

        if (
            this.props.user.role === ROLE.CEMETERY ||
            this.props.user.role === ROLE.CEMETERY_PUBLIC
        ) {
            this.layers.push(new CemeteryUAVLayer(this));
            this.layers.push(new CemeteryObjectLayer(this));
            this.layers.push(new CemeteryGraveLayer(this, this.props.user));
        }

        this.layers.forEach((layerObject) => {
            const layers = Array.isArray(layerObject.getLayer())
                ? layerObject.getLayer()
                : [layerObject.getLayer()];
            layers.forEach((layer) => this.map.addLayer(layer));
        });

        this.map.updateSize();
    }

    render() {
        const { classes, className } = this.props;
        return (
            <>
                <div id={'map'} className={classNames(classes.map, className)} />
                {this.props.user && this.props.user.role === ROLE.PROKAT && <ProkatLegend />}
                {this.props.user &&
                    (this.props.user.role === ROLE.CEMETERY ||
                        this.props.user.role === ROLE.CEMETERY_PUBLIC) && <CemeteryLegend />}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.user;
    const { layers } = state.map;
    const { damage, damagePlot, manageSamplings, manageDrawings } = state.damage;
    const { drawing } = state.drawing;
    const { infoPanelState } = state.view;
    const { selectedDeads, selectedGraves } = state.cemetery;
    return {
        user,
        layers,
        damage,
        damagePlot,
        drawing,
        infoPanelState,
        manageSamplings,
        manageDrawings,
        selectedDeads,
        selectedGraves,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(MyMap)));
