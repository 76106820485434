export const VIEWS = {
    DAMAGE: 1,
    DAMAGE_PLOT: 2,
    POLICY: 3,
    POLICY_PLOT: 4,
    BLOCK: 5,
    DAMAGE_BLOCK: 6,
    DRAWING: 7,
    PRODUCT: 8,
    PROGRESS: 9,
    RADAR: 10,
    HOME: 11,
    BLOCK_PRODUCT: 12,
    SAMPLING: 13,
    WOODWISER_PROJECT: 14,
    WOODWISER_FOREST_UNIT: 15,
    EK: 16,
    EK_PRODUCT: 17,
    AGRO_HOME: 18,
    AGRO_BLOCK_PLOT: 19,
    DAMAGE_CHRONOLOGY: 20,
    YIELD: 21,
};

export const FARMER_VIEWS = {
    NDVI: 16,
    ALERT: 17,
};
