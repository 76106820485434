export const LOGS = {
    TYPES: {
        LOGIN: 1,
        CONNECT: 2,
        UPDATE_USER: 3,
        UPDATE_USER_PASSWORD: 4,
        SEARCH: 5,
        GET_POLICY: 6,
        UPDATE_POLICIES: 7,
        GET_RADAR: 8,
        GET_RADAR_EXCEL: 9,
        GET_CROP_MAP: 10,
        GET_DAMAGE: 11,
        GET_DAMAGE_PLOT: 12,
        UPDATE_DAMAGES: 13,
        SAVE_DAMAGE_PLOT_OUTLINE: 14,
        GET_BLOCK: 15,
        GET_PRODUCT_PDF: 16,
        DRAWING_NEW_STARTED: 17,
        DRAWING_MEPAR_STARTED: 18,
        DRAWING_PLOT_STARTED: 19,
        DRAWING_FINISHED: 20,
        CREATE_DAMAGE: 21,
        CREATE_DAMAGE_FROM_EXCEL: 22,
        SAVE_SAMPLING_PLAN: 23,
        SAVE_SAMPLING: 24,
        GET_WOODWISER_PROJECT: 25,
        GET_WOODWISER_FOREST_UNIT: 26,
        LOGIN_LINK_TOKEN: 27,
        SUPPRESS_WELCOME_SCREEN: 28,
        SAVE_POLICY_PLOT_OUTLINE: 29,
        GET_MEPARS: 30,
        GET_ALERTS: 31,
        SET_SELECTED_MEPAR: 32,
        SET_PRODUCT_DATE: 33,
        SET_NDVI_LAYER: 34,
        SET_ALERT_LAYER: 35,
        DRAWING_POLICY_PLOT_STARTED: 36,
        DRAWING_POLICY_PLOT_FINISHED: 37,
        SET_VIEW_STATE: 38,
        SHOW_POLICY_PLOT_DIALOG: 39,
        HIDE_POLICY_PLOT_DIALOG: 40,
        UPDATE_SAMPLINGS: 41,
        GET_DAMAGE_STATS: 42,
        GET_PROKAT_SOIL_ZONE: 43,
        GET_PROKAT_SOIL_SAMPLE: 44,
        GET_PROKAT_SOIL_SAMPLE_MEPAR: 45,
        GET_PROKAT_SOIL_MANAGEMENT_ZONE: 46,
        GET_PRODUCTS: 47,
        GET_GRAVES: 48,
        UPDATE_INSPECTION_DOCUMENTS: 49,
        GET_CEMETERY_OBJECTS: 50,
        DRAWING_PREVIOUS_TABLE_GEOMETRY_STARTED: 51,
        GET_GRAVEYARD: 52,
        GET_DASHBOARD: 53,
        UPDATE_DAMAGE_PHOTOS: 54,
        GET_DAMAGE_PHOTOS: 55,
        CALCULATE_YIELD: 56,
        GENERATE_ZONES: 57,
        GET_POLICY_YIELDS: 58,
    },
};
