import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../screens/Login';
import { ROUTES } from 'shared/src/constants/routes';
import PasswordReset from '../screens/PasswordReset';
import PasswordRenew from '../screens/PasswordRenew';
import { useDispatch, useSelector } from 'react-redux';
import ErrorScreen from '../screens/ErrorScreen';
import { matchPath, useLocation } from 'react-router';
import NotFound from '../screens/NotFound';
import { Helmet } from 'react-helmet';
import { ROLE, USER_STATUS } from 'shared';
import SecureRoute from '../components/routes/SecureRoute';
import UserProfile from '../screens/UserProfile';
import CreateDamage from '../screens/CreateDamage';
import Sampling from '../screens/Sampling';
import MainView from '../screens/MainView';
import { userActions } from '../state/actions';
import WelcomeDialog from '../components/welcome/WelcomeDialog';
import { SERVER_ERROR_INVALID_CREDENTIALS } from '../services/baseService';
import Dashboard from '../screens/Dashboard';

const pathDefs = [
    { path: ROUTES.LOGIN(), component: Login },
    { path: ROUTES.PASSWORD_RESET(), component: PasswordReset },
    { path: ROUTES.NEW_PASSWORD_RESET(), component: PasswordRenew },
    { path: ROUTES.HOME(), secure: true, exact: true, component: MainView },
    { path: ROUTES.USER_PROFILE(), secure: true, component: UserProfile },
    { path: ROUTES.CREATE_DAMAGE(), secure: true, component: CreateDamage },
    { path: ROUTES.SAMPLING(':id'), secure: true, component: Sampling },
    { path: ROUTES.BLOCK(':id'), secure: true, component: MainView },
    { path: ROUTES.DAMAGE(':id'), secure: true, component: MainView },
    { path: ROUTES.POLICY(':id'), secure: true, component: MainView },
    { path: ROUTES.DAMAGE_CHRONOLOGY(), secure: true, component: MainView },
    { path: ROUTES.YIELD(':type', ':plant'), secure: true, component: MainView },
    { path: ROUTES.BLOCK_PLOT(':id'), secure: true, component: MainView },
    { path: ROUTES.WOODWISER_PROJECT(':id'), secure: true, component: MainView },
    { path: ROUTES.WOODWISER_FOREST_UNIT(':id'), secure: true, component: MainView },
    { path: ROUTES.RADAR(), secure: true, component: MainView },
    { path: ROUTES.DASHBOARD(), secure: true, component: Dashboard },
    {
        path: ROUTES.ALERT_LINK_TOKEN(':compoundLinkToken', ':view', ':date'),
        secure: true,
        component: MainView,
    },
    { path: ROUTES.LINK_TOKEN(':compoundLinkToken'), secure: true, component: MainView },
    { path: ROUTES.SOIL_SAMPLING_MEPAR(':meparCode'), secure: true, component: MainView },
    { path: ROUTES.EK(':ekCode'), secure: true, component: MainView },
];

export default function Routes() {
    const error = useSelector((state) => state.error.error);
    const userStatus = useSelector((state) => state.user.status);
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const location = useLocation();

    const pathDef = pathDefs.find((pathDef) => matchPath(location.pathname, pathDef));

    useEffect(() => {
        if (user) {
            return;
        }

        if (pathDef.secure) {
            dispatch(userActions.requestUser(location));
        }
    }, [dispatch, pathDef, location, user]);

    if (error && error.code !== SERVER_ERROR_INVALID_CREDENTIALS) {
        return <ErrorScreen />;
    }

    if (pathDef.secure && userStatus !== USER_STATUS.FETCHED) {
        return null;
    }

    return (
        <>
            <Helmet>
                <title>
                    {user && user.role === ROLE.FARMER
                        ? 'Kárriasztás'
                        : user && user.role === ROLE.AGRARMONITOR
                        ? 'Agrármonitor'
                        : user && user.role === ROLE.AGRO
                        ? 'Envirosense Agro'
                        : user &&
                          (user.role === ROLE.CEMETERY || user.role === ROLE.CEMETERY_PUBLIC)
                        ? 'Temető'
                        : 'GeoRisk'}
                </title>
            </Helmet>
            <Switch>
                {pathDefs.map((pathDef, index) =>
                    pathDef.secure ? (
                        <SecureRoute
                            key={index}
                            path={pathDef.path}
                            exact={pathDef.exact}
                            component={pathDef.component}
                        />
                    ) : (
                        <Route
                            key={index}
                            path={pathDef.path}
                            exact={pathDef.exact}
                            component={pathDef.component}
                        />
                    )
                )}
                <Route component={NotFound} />
            </Switch>
            <ErrorScreen />
            <WelcomeDialog />
        </>
    );
}
