import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Downshift from 'downshift';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { searchService } from '../../services';
import { ROUTES } from 'shared/src/constants/routes';
import { ROLE } from 'shared';
import * as React from 'react';
import { Component } from 'react';
import { cemeteryActions } from '../../state/actions';

function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
        <TextField
            InputProps={{
                inputRef: ref,
                disableUnderline: true,
                classes: {
                    root: classes.inputRoot,
                    input: classes.inputInput,
                },
                ...InputProps,
            }}
            {...other}
        />
    );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem && suggestion && selectedItem.id === suggestion.id;

    let text = '';
    switch (suggestion.type) {
        case 'damage':
            text = suggestion.damageNumber;
            break;
        case 'block':
            text = suggestion.meparCode;
            break;
        case 'policy':
            text = suggestion.policyNumber;
            break;
        case 'woodwiserProject':
            text = suggestion.name;
            break;
        case 'woodwiserForestUnit':
            text = suggestion.name;
            break;
        case 'prokatMeparCode':
            text = suggestion.meparCode;
            break;
        case 'agrarmonitorEKCode':
            text = suggestion.ekCode;
            break;
        case 'blockPlot':
            text = `${suggestion.meparCode} - ${suggestion.name}`;
            break;
        case 'dead':
            text = suggestion.name;
            break;
        case 'deadsByBirth':
            text = `Születettek: ${suggestion.deadsByBirth.length}`;
            break;
        case 'deadsByDeath':
            text = `Elhunytak: ${suggestion.deadsByDeath.length}`;
            break;
        case 'gravesByValidDate':
            text = `Sírhely lejárat: ${suggestion.gravesByValidDate.length}`;
            break;
        default:
            throw new Error(`Unknown suggestion type ${suggestion.type}`);
    }

    return (
        <MenuItem
            {...itemProps}
            key={index}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {text}
        </MenuItem>
    );
}

const styles = (theme) => ({
    paper: {
        position: 'absolute',
        zIndex: 200,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
        margin: '0px',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(6),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 200,
        },
    },
});

class SearchField extends Component {
    state = {
        results: [],
    };

    getSuggestions = async (phrase) => {
        const value = phrase || '';

        const results = await searchService.search(value, 5);

        this.setState({
            results,
        });
    };

    handleOnFocus = (inputValue, openMenu) => async () => {
        await this.getSuggestions(inputValue);
        openMenu();
    };

    onItemSelected = (selection) => {
        switch (selection.type) {
            case 'damage':
                this.props.history.push(ROUTES.DAMAGE(selection.damageNumber));
                break;
            case 'block':
                this.props.history.push(ROUTES.BLOCK(selection.meparCode));
                break;
            case 'policy':
                this.props.history.push(ROUTES.POLICY(selection.policyNumber));
                break;
            case 'woodwiserProject':
                this.props.history.push(ROUTES.WOODWISER_PROJECT(selection.id));
                break;
            case 'woodwiserForestUnit':
                this.props.history.push(ROUTES.WOODWISER_FOREST_UNIT(selection.id));
                break;
            case 'prokatMeparCode':
                this.props.history.push(ROUTES.SOIL_SAMPLING_MEPAR(selection.meparCode));
                break;
            case 'agrarmonitorEKCode':
                this.props.history.push(ROUTES.EK(selection.ekCode));
                break;
            case 'blockPlot':
                this.props.history.push(ROUTES.BLOCK_PLOT(selection.id));
                break;
            case 'dead':
                this.props.dispatch(cemeteryActions.setSelectedDeads([selection]));
                break;
            case 'deadsByBirth':
                this.props.dispatch(cemeteryActions.setSelectedDeads(selection.deadsByBirth));
                break;
            case 'deadsByDeath':
                this.props.dispatch(cemeteryActions.setSelectedDeads(selection.deadsByDeath));
                break;
            case 'gravesByValidDate':
                this.props.dispatch(cemeteryActions.setSelectedGraves(selection.gravesByValidDate));
                break;

            default:
                throw new Error(`Unknown suggestion type ${selection.type}`);
        }
    };

    render() {
        const { classes, user } = this.props;
        const { results } = this.state;
        return (
            <Downshift
                id="downshift-simple"
                onChange={this.onItemSelected}
                itemToString={(item) => {
                    if (!item) {
                        return '';
                    }
                    return item.meparCode || item.damageNumber || item.name;
                }}
                onInputValueChange={this.getSuggestions}
            >
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    highlightedIndex,
                    isOpen,
                    selectedItem,
                    inputValue,
                    openMenu,
                }) => (
                    <div>
                        {renderInput({
                            fullWidth: true,
                            classes,
                            InputProps: getInputProps({
                                placeholder:
                                    user.role === ROLE.AGRARMONITOR
                                        ? 'EK azonosító...'
                                        : user.role === ROLE.AGRO
                                        ? 'MePAR, tábla, ...'
                                        : user.role === ROLE.CEMETERY ||
                                          user.role === ROLE.CEMETERY_PUBLIC
                                        ? 'Név, szül. dátum, ...'
                                        : 'MePAR kód...',
                                onFocus: this.handleOnFocus(inputValue, openMenu),
                            }),
                        })}
                        <div {...getMenuProps()}>
                            {isOpen ? (
                                <Paper className={classes.paper} square={true}>
                                    {results.map((suggestion, index) =>
                                        renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion }),
                                            highlightedIndex,
                                            selectedItem,
                                        })
                                    )}
                                </Paper>
                            ) : null}
                        </div>
                    </div>
                )}
            </Downshift>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.user;

    return {
        user,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(SearchField)));
