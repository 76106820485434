export const ROLE = {
    API: 1,
    ADMIN: 2,
    MANAGER: 3,
    EXPERT_DAMAGE: 4,
    EXPERT_UW: 5,
    FARMER: 6,
    WOODWISER: 7,
    OPERATOR: 8,
    PROKAT: 9,
    AGRARMONITOR: 10,
    AGRO: 11,
    CEMETERY: 12,
    CEMETERY_PUBLIC: 13,
};
