import React, { useRef, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Button, CircularProgress, Divider, List, ListItem, Typography } from '@material-ui/core';
import { Add, Cancel, Delete, Done, Edit, GpsFixed } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { LAYERS } from 'shared';
import { roundHectareArea } from '../../helpers/areaCalculator';
import { samplingService } from '../../services/samplingService';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import {
    drawingActions,
    mapActions,
} from '../../state/actions';

const styles = (theme) => ({
    panelLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    groupTitle: {
        fontSize: '18px',
    },
    error: {
        fontSize: '13px',
        fontWeight: 'bold',
        color: 'red',
        textAlign: 'center',
        width: '100%',
    },
    description: {
        fontSize: '14px',
        paddingBottom: '30px',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    divider: {
        marginTop: '10px;',
    },
    loader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    progress: {
        minWidth: '100px',
        fontSize: '13px',
        marginBottom: '10px',
    },
});

const SamplingPanel = (props) => {
    const { classes, damagePlot, drawing, layers } = props;
    const { showModal } = useModals();
    const [editing, setEditing] = useState(false);
    const [zonesPresent, setZonesPresent] = useState(false);
    const [generating, setGenerating] = useState(false);
    const firstUpdate = useRef(true);
    const samplingLayer = layers.find((l) => l.type === LAYERS.TYPES.SAMPLING);
    const drawingLayer = layers.find((l) => l.type === LAYERS.TYPES.DRAWING);

    // Open samplingmodal on if there's a drawing present
    // (if drawing left in redux from other component, ignore first load)
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (drawing) {
            if (drawing.removable) {
                showModal(MODAL_TYPE.CONFIRM, {
                    title: 'Mintavételi pont törlése',
                    message: 'Biztosan törölni szeretné?',
                    onConfirm: () => handleRemoveSampling(drawing.sequenceId),
                    onCancel: () => onModalClose(false),
                });
                return;
            }
            showModal(MODAL_TYPE.SAMPLING, {
                drawing,
                onSave: (samplingData) => handleSaveSampling(samplingData),
                onClose: (pop) => onModalClose(pop),
            });
        }
    }, [drawing]);

    useEffect(() => {
        setZonesPresent(damagePlot.sampling.some((s) => s.isGenerated));
    }, [damagePlot]);

    const onGenerateZones = () => async () => {
        const errors = [];

        if (!damagePlot.tableGeometry || !damagePlot.tableGeometryHa === 0) {
            errors.push('A károsodott terület megrajzolása szükséges.');
        }

        if (errors.length > 0) {
            showModal(MODAL_TYPE.STATUS, {
                title: 'A zónagenerálás nem lehetséges',
                messages: errors,
                onClose: () => showModal(MODAL_TYPE.NONE),
            });
            return;
        }

        setGenerating(true);

        const response = await samplingService.startZonesGeneration(damagePlot.id);

        if (response.err) {
            showModal(MODAL_TYPE.STATUS, {
                title: 'Zónagenerálás sikertelen',
                messages: [response.err],
                onClose: () => showModal(MODAL_TYPE.NONE),
            });
            setGenerating(false);
            return;
        }

        showModal(MODAL_TYPE.STATUS, {
            title: 'Sikeres zónagenerálás',
            messages: ['A generált zónatérkép betöltéséhez az oldalt újratöltjük.'],
            onClose: () => {
                showModal(MODAL_TYPE.NONE);
                window.location.reload(true);
            },
        });

        setGenerating(false);
    };

    const clearUp = (pop) => {
        setEditing(false);

        samplingLayer.newPoint = false;
        samplingLayer.setPointData = false;
        samplingLayer.setPosition = false;
        samplingLayer.removePoint = false;

        if (pop) {
            samplingLayer.damagePlot.sampling.pop();
        }

        props.dispatch(mapActions.updateLayer(samplingLayer));
        props.dispatch(drawingActions.setDrawing(null));
        props.dispatch(mapActions.updateLayer(drawingLayer));
    }

    // Function that runs when modal is closed without saving
    const onModalClose = (pop) => {
        showModal(MODAL_TYPE.NONE);
        clearUp(pop);
    };

    // Start editing
    const handleEditOnMap = (editType) => {
        setEditing(editType);
        samplingLayer[editType] = true;
        props.dispatch(mapActions.updateLayer(samplingLayer));
    };

    // Save new or updated data to db
    const handleSaveSampling = async (samplingData) => {
        damagePlot.sampling = damagePlot.sampling.filter(
            (s) => s.sequenceId !== samplingData.sequenceId
        );
        damagePlot.sampling.push(samplingData);

        const result = await samplingService.saveSampling(damagePlot.id, damagePlot.sampling);

        if (result.err) {
            showModal(MODAL_TYPE.STATUS, {
                title: 'Sikertelen mentés',
                messages: [`A hiba oka: ${result.err}`],
                onClose: () => onModalClose(false),
            });
            return;
        }

        showModal(MODAL_TYPE.STATUS, {
            title: 'Sikeres mentés',
            messages: [`Mintavételi pontok mentve.`],
            onClose: () => onModalClose(false),
        });
    };

    // Remove selected point from db
    const handleRemoveSampling = async (sequenceId) => {
        showModal(MODAL_TYPE.NONE);
        const removed = await samplingService.removeSampling(damagePlot.id, sequenceId);

        if (removed.err) {
            showModal(MODAL_TYPE.STATUS, {
                title: 'Sikertelen törlés',
                messages: [`A hiba oka: ${removed.err}`],
                onClose: () => onModalClose(false),
            });
            return;
        }

        damagePlot.sampling = damagePlot.sampling.filter(
            (s) => s.sequenceId !== sequenceId
        );

        showModal(MODAL_TYPE.STATUS, {
            title: 'Sikeres törlés',
            messages: [`A(z) ${removed.removed}. pont eltávolításra került.`],
            onClose: () => onModalClose(false),
        });
    }

    // Save new positions to db
    const handleSavePositions = async () => {
        const result = await samplingService.saveSampling(damagePlot.id, damagePlot.sampling);

        if (result.err) {
            showModal(MODAL_TYPE.STATUS, {
                title: 'Sikertelen mentés',
                messages: [`A hiba oka: ${result.err}`],
                onClose: () => onModalClose(false),
            });
            return;
        }
        
        showModal(MODAL_TYPE.STATUS, {
            title: 'Sikeres mentés',
            messages: [`Mintavételi pontok mentve.`],
            onClose: () => onModalClose(false),
        });
    };

    const handleCancelChanges = () => {
        clearUp(false);
    };

    return (
        <div className={classes.panelLayout}>
            <List>
                <ListItem>
                    <div className={classes.layout}>
                        <div className={classes.row}>
                            <Typography className={classes.label}>MePAR terület:</Typography>
                            <Typography className={classes.value}>
                                {`${roundHectareArea(damagePlot.mepar.geomHa)} ha`}
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Tábla terület:</Typography>
                            <Typography className={classes.value}>
                                {damagePlot.tableGeometry
                                    ? `${roundHectareArea(damagePlot.tableGeometryHa)} ha`
                                    : 'Rajzolás szükséges!'}
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Kár. terület:</Typography>
                            <Typography className={classes.value}>
                                {`${damagePlot.damagedArea} ha`}
                            </Typography>
                        </div>
                    </div>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <img src={'/artifacts/Kronologia/infoNDVI.gif'} width={'290px'} alt={''} />
                </ListItem>

                <Divider />
                {/*
                <ListItem>
                    <Button variant="contained" color="default" size="small" className={classes.button}
                            onClick={handleSaveSampling}>
                        {`Mintavételi terv mentése`}
                    </Button>
                </ListItem>
                <ListItem>
                    <Button variant="contained" color="default" size="small" className={classes.button}
                            onClick={onCancelSamplingChanges}>
                        {`Módosítások elvetése`}
                    </Button>
                </ListItem>

*/}
                <ListItem>
                    <form
                        method="get"
                        action={`/api/downloads/sampling/${encodeURIComponent(damagePlot.id)}`}
                        target="_blank"
                        className={classes.button}
                    >
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            className={classes.button}
                            type="submit"
                        >
                            {`Mintavételi terv letöltése (KML)`}
                        </Button>
                    </form>
                </ListItem>
                <Divider />
                {!zonesPresent ? (
                    <List>
                        <ListItem>
                            <Typography className={classes.error}>
                                Mintavételi zónák kijelölése szükséges.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            {generating ? (
                                <ListItem className={classes.loader}>
                                    <Typography className={classes.progressTest}>
                                        Zónakijelölés folyamatban...
                                    </Typography>
                                    <Typography className={classes.progressTest}>
                                        (Akár 1 percig is eltarthat.)
                                    </Typography>
                                    <CircularProgress />
                                </ListItem>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    className={classes.button}
                                    onClick={onGenerateZones()}
                                    disabled={generating}
                                >
                                    Zónakijelölés indítása
                                </Button>
                            )}
                        </ListItem>
                    </List>
                ) : (
                    <List>
                        <ListItem>
                            {editing === 'setPointData' ? (
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleCancelChanges}
                                >
                                    <Cancel style={{ marginRight: '5px' }} /> Mégse
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    className={classes.button}
                                    onClick={() => handleEditOnMap('setPointData')}
                                    disabled={editing}
                                >
                                    <Edit style={{ marginRight: '5px' }} /> Pontok módosítása
                                </Button>
                            )}
                        </ListItem>
                        <ListItem>
                            {editing === 'setPosition' ? (
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleSavePositions}
                                >
                                    <Done style={{ marginRight: '5px' }} /> Módosítások mentése
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    className={classes.button}
                                    onClick={() => handleEditOnMap('setPosition')}
                                    disabled={editing}
                                >
                                    <GpsFixed style={{ marginRight: '5px' }} /> Pontok mozgatása
                                </Button>
                            )}
                        </ListItem>
                        <ListItem>
                            {editing === 'newPoint' ? (
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleCancelChanges}
                                >
                                    <Cancel style={{ marginRight: '5px' }} /> Mégse
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    className={classes.button}
                                    onClick={() => handleEditOnMap('newPoint')}
                                    disabled={editing}
                                >
                                    <Add style={{ marginRight: '5px' }} /> Új pont hozzáadása
                                </Button>
                            )}
                        </ListItem>
                        <ListItem>
                            {editing === 'removePoint' ? (
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleCancelChanges}
                                >
                                    <Cancel style={{ marginRight: '5px' }} /> Mégse
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    className={classes.button}
                                    onClick={() => handleEditOnMap('removePoint')}
                                    disabled={editing}
                                >
                                    <Delete style={{ marginRight: '5px' }} /> Pont eltávolítása
                                </Button>
                            )}
                        </ListItem>
                    </List>
                )}
            </List>
        </div>
    );
};

export default withStyles(styles)(withRouter(SamplingPanel));
