import React from 'react';
import MyMap from '../components/maps/MyMap';
import MyMapOld from '../components/maps_old/Map';
import MainScreen from '../components/screens/MainScreen';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router';
import { ROLE, ROUTES } from 'shared';
import FarmerHomeController from '../controllers/FarmerHomeController';
import AgroHomeController from '../controllers/AgroHomeController';
import CemeteryHomeController from '../controllers/CemeteryHomeController';
import HomeController from '../controllers/HomeController';
import BlockController from '../controllers/BlockController';
import DamageController from '../controllers/DamageController';
import PolicyController from '../controllers/PolicyController';
import WoodwiserProjectController from '../controllers/WoodwiserProjectController';
import WoodwiserForestUnitController from '../controllers/WoodwiserForestUnitController';
import RadarController from '../controllers/RadarController';
import { useSelector } from 'react-redux';
import NavigationBar from '../components/navigation/NavigationBar';
import PolicyPlotsDialog from '../components/maps/popups/PolicyPlotsDialog';
import DrawingBar from '../components/drawing/DrawingBar';
import InfoPanel from '../components/info/InfoPanel';
import FarmerBlockController from '../controllers/FarmerBlockController';
import ViewModeBar from '../components/navigation/ViewModeBar';
import SoilSamplingController from '../controllers/SoilSamplingController';
import EKController from '../controllers/EKController';
import AgroBlockPlotController from '../controllers/AgroBlockPlotController';
import DamageChronologyController from '../controllers/DamageChronologyController';
import YieldsController from '../controllers/YieldsController';

const useStyles = makeStyles((theme) => ({
    align: {
        width: '100%',
        display: 'flex',
        flex: 1,
        height: 'calc(100% - 56px)',
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            height: 'calc(100% - 64px)',
        },
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100% - 64px)',
        },
    },
    map: {
        height: 'calc(100% - 140px)',
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            height: 'calc(100% - 156px)',
        },
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100% - 156px)',
        },
    },
}));

export default function MainView() {
    const classes = useStyles();
    const user = useSelector((state) => state.user.user);

    if (!user) {
        return null;
    }

    return (
        <MainScreen>
            {user && user.role === ROLE.FARMER && (
                <>
                    <NavigationBar />
                    <DrawingBar />
                    <div className={classes.map}>
                        <MyMap />
                    </div>
                    <ViewModeBar />

                    <PolicyPlotsDialog />
                </>
            )}
            {user && user.role !== ROLE.FARMER && (
                <div className={classes.align}>
                    {user.role !== ROLE.PROKAT && <InfoPanel />}
                    <MyMapOld />
                </div>
            )}

            <Switch>
                <Route
                    path={ROUTES.HOME()}
                    exact={true}
                    component={
                        user.role === ROLE.FARMER
                            ? FarmerHomeController
                            : user.role === ROLE.AGRO
                            ? AgroHomeController
                            : user.role === ROLE.CEMETERY || user.role === ROLE.CEMETERY_PUBLIC
                            ? CemeteryHomeController
                            : HomeController
                    }
                />
                <Route
                    path={ROUTES.ALERT_LINK_TOKEN(':compoundLinkToken', ':view', ':date')}
                    exact={true}
                    component={FarmerHomeController}
                />
                <Route
                    path={ROUTES.LINK_TOKEN(':compoundLinkToken')}
                    exact={true}
                    component={
                        user.role === ROLE.CEMETERY || user.role === ROLE.CEMETERY_PUBLIC
                            ? CemeteryHomeController
                            : FarmerHomeController
                    }
                />
                <Route
                    path={ROUTES.BLOCK(':id')}
                    component={user.role === ROLE.FARMER ? FarmerBlockController : BlockController}
                />
                <Route path={ROUTES.DAMAGE(':id')} component={DamageController} />
                <Route path={ROUTES.POLICY(':id')} component={PolicyController} />
                <Route path={ROUTES.BLOCK_PLOT(':id')} component={AgroBlockPlotController} />
                <Route path={ROUTES.DAMAGE_CHRONOLOGY()} component={DamageChronologyController} />
                <Route path={ROUTES.YIELD(':type', ':plant')} component={YieldsController} />
                <Route
                    path={ROUTES.WOODWISER_PROJECT(':id')}
                    component={WoodwiserProjectController}
                />
                <Route
                    path={ROUTES.WOODWISER_FOREST_UNIT(':id')}
                    component={WoodwiserForestUnitController}
                />
                <Route path={ROUTES.RADAR()} component={RadarController} />
                <Route
                    path={ROUTES.SOIL_SAMPLING_MEPAR(':meparCode')}
                    component={SoilSamplingController}
                />
                <Route path={ROUTES.EK(':ekCode')} component={EKController} />
            </Switch>
        </MainScreen>
    );
}
