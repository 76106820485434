import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import DamagePanel from './DamagePanel';
import DamagePlotPanel from './DamagePlotPanelContainer';
import { connect } from 'react-redux';
import ProductPanel from './ProductPanel';
import DrawingsPanel from './DrawingsPanelContainer';
import { VIEWS } from 'shared/src/constants/views';
import PolicyPanel from './PolicyPanel';
import BlockPanel from './BlockPanel';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { List, ListItem } from '@material-ui/core';
import PolicyPlotPanel from './PolicyPlotPanel';
import RadarPanel from './RadarPanel';
import InfoPanelHeader from './InfoPanelHeader';
import { viewActions } from '../../state/actions';
import classNames from 'classnames';
import BlockProductPanel from './BlockProductPanel';
import SamplingPanel from './SamplingPanelContainer';
import WoodwiserProjectPanel from './WoodwiserProjectPanel';
import WoodwiserForestUnitPanel from './WoodwiserForestUnitPanel';
import AgroHomePanel from './AgroHomePanel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EKPanel from './EKPanel';
import EKProductPanel from './EKProductPanel';
import AgroBlockPlotPanel from './AgroBlockPlotPanel';
import DamageChronologyPanel from './DamageChronologyPanel';
import YieldsPanel from './YieldsPanel';

const styles = (theme) => ({
    layout: {
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    drawerWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    hidden: {
        display: 'none !important',
    },
    drawer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.3)',
        minHeight: 0,
        width: '100%',
        [theme.breakpoints.up('md')]: {
            overflow: 'auto',
            width: theme.drawerWidth,
        },
    },
    button: {
        paddingLeft: '5px',
        paddingRight: '5px',
        maxWidth: '25px',
        minWidth: '25px',
        minHeight: '50px',
        maxHeight: '50px',
        borderRadius: '0px',
        position: 'absolute',
        top: '68px',
        zIndex: 100,
        backgroundColor: 'rgba(255,255,255,1)',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,1)',
        },
        boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.3)',
        borderLeftWidth: '1px',
        borderLeftColor: 'rgba(100,100,100,1)',
    },
    buttonOpen: {
        left: '320px',
    },
    buttonClosed: {
        left: '0px',
    },
    title: {
        fontSize: '18px',
        paddingBottom: '30px',
    },
    progressBar: {
        width: '100%',
        display: 'flex',
        marginTop: '30px',
        justifyContent: 'space-around',
    },
});

class InfoPanel extends React.Component {
    toggleInfoPanel = () => {
        this.props.dispatch(
            viewActions.setInfoPanelState({ open: !this.props.infoPanelState.open })
        );
    };

    render() {
        const { className, classes, viewState, infoPanelState, damagePlotAreas } = this.props;

        if (infoPanelState && infoPanelState.hidden) {
            return null;
        }

        return (
            <div
                className={classNames(
                    className,
                    classes.layout,
                    !viewState || viewState.view === VIEWS.HOME ? classes.hidden : null
                )}
            >
                <div
                    className={classNames(
                        classes.drawerWrapper,
                        infoPanelState && infoPanelState.open ? null : classes.hidden
                    )}
                >
                    <Paper className={classNames(classes.drawer)}>
                        <InfoPanelHeader />
                        {viewState && viewState.view === VIEWS.PROGRESS && (
                            <List>
                                <ListItem>
                                    <div className={classes.progressBar}>
                                        <CircularProgress
                                            className={classes.progress}
                                            color="primary"
                                        />
                                    </div>
                                </ListItem>
                            </List>
                        )}
                        {viewState && viewState.view === VIEWS.DAMAGE && <DamagePanel />}
                        {viewState && viewState.view === VIEWS.DAMAGE_PLOT && <DamagePlotPanel />}
                        {viewState && viewState.view === VIEWS.DRAWING && <DrawingsPanel />}
                        {viewState && viewState.view === VIEWS.SAMPLING && <SamplingPanel />}
                        {viewState && viewState.view === VIEWS.PRODUCT && <ProductPanel />}
                        {viewState && viewState.view === VIEWS.POLICY && <PolicyPanel />}
                        {viewState && viewState.view === VIEWS.POLICY_PLOT && <PolicyPlotPanel />}
                        {viewState && viewState.view === VIEWS.BLOCK && <BlockPanel />}
                        {viewState && viewState.view === VIEWS.BLOCK_PRODUCT && (
                            <BlockProductPanel />
                        )}
                        {viewState && viewState.view === VIEWS.RADAR && <RadarPanel />}
                        {viewState && viewState.view === VIEWS.WOODWISER_PROJECT && (
                            <WoodwiserProjectPanel />
                        )}
                        {viewState && viewState.view === VIEWS.WOODWISER_FOREST_UNIT && (
                            <WoodwiserForestUnitPanel />
                        )}
                        {viewState && viewState.view === VIEWS.AGRO_HOME && <AgroHomePanel />}
                        {viewState && viewState.view === VIEWS.AGRO_BLOCK_PLOT && (
                            <AgroBlockPlotPanel />
                        )}
                        {viewState && viewState.view === VIEWS.DAMAGE_CHRONOLOGY && (
                            <DamageChronologyPanel />
                        )}
                        {viewState && viewState.view === VIEWS.YIELD && (
                            <YieldsPanel />
                        )}
                        {viewState && viewState.view === VIEWS.EK && <EKPanel />}
                        {viewState && viewState.view === VIEWS.EK_PRODUCT && <EKProductPanel />}
                    </Paper>
                </div>
                <Button
                    className={classNames(
                        classes.button,
                        infoPanelState && infoPanelState.open
                            ? classes.buttonOpen
                            : classes.buttonClosed
                    )}
                    onClick={this.toggleInfoPanel}
                >
                    {infoPanelState && infoPanelState.open ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { viewState, infoPanelState } = state.view;
    return {
        viewState,
        infoPanelState,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(InfoPanel));
