import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CircularProgress, List, ListItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button/Button';
import moment from 'moment';
import { ROUTES } from 'shared/src/constants/routes';
import { PRODUCTS } from 'shared/src/constants/products';
import { yieldCalculationService } from '../../services/yieldCalculationService';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import { roundHectareArea } from '../../helpers/areaCalculator';
import classNames from 'classnames';

moment.locale('hu');

const styles = (theme) => ({
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    error: {
        fontSize: '13px',
        fontWeight: 'bold',
        color: 'red',
        textAlign: 'center',
        width: '100%',
    },
    label: {
        minWidth: '110px',
        fontSize: '13px',
    },
    redLabel: {
        color: 'red',
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    highlightedValue: {
        color: 'red',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    loader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    progress: {
        minWidth: '100px',
        fontSize: '13px',
        marginBottom: '10px',
    },
});

function DamagePlotPanel({ classes, damage, damagePlot }) {
    const { showModal } = useModals();
    const history = useHistory();
    const [calculatedYield, setCalculatedYield] = useState(damagePlot.calculatedYield);
    const [calculating, setCalculating] = useState(false);
    const [meparBorderIsMissing, setmeparBorderIsMissing] = useState(!damagePlot.meparPolygon);
    const [zonesPresent, setZonesPresent] = useState(false);
    const [productTypeMap, setProductTypeMap] = useState(
        damagePlot.products
            ? damagePlot.products.reduce((accumulator, product) => {
                  const productsInType = accumulator[product.type] || [];
                  productsInType.push(product);
                  return Object.assign(accumulator, {
                      [product.type]: productsInType,
                  });
              }, {})
            : {}
    );

    useEffect(() => {
        setZonesPresent(damagePlot.sampling.some((s) => s.isGenerated));
    }, [damagePlot]);

    useEffect(() => {
        setmeparBorderIsMissing(!damagePlot.meparPolygon);
        setProductTypeMap(
            damagePlot.products
                ? damagePlot.products.reduce((accumulator, product) => {
                      const productsInType = accumulator[product.type] || [];
                      productsInType.push(product);
                      return Object.assign(accumulator, {
                          [product.type]: productsInType,
                      });
                  }, {})
                : {}
        );
    }, [damagePlot]);

    const onShowProduct = (product) => () => {
        history.push(
            `${ROUTES.DAMAGE(damagePlot.damage.damageNumber)}?damagePlotId=${
                damagePlot.id
            }&productType=${product.type}`
        );
    };

    const onShowRadar = () => () => {
        history.push(`${ROUTES.RADAR()}?damagePlotId=${damagePlot.id}`);
    };

    const onShowDrawings = () => () => {
        history.push(
            `${ROUTES.DAMAGE(damagePlot.damage.damageNumber)}?damagePlotId=${
                damagePlot.id
            }&manageDrawings=true`
        );
    };

    const onShowSamplings = () => () => {
        history.push(
            `${ROUTES.DAMAGE(damagePlot.damage.damageNumber)}?damagePlotId=${
                damagePlot.id
            }&manageSamplings=true`
        );
    };

    const onCalculateYield = () => async () => {
        const errors = [];

        if (meparBorderIsMissing) {
            errors.push('A MePAR körvonal hiányzik!');
        }

        if (!damagePlot.tableGeometry || !damagePlot.tableGeometryHa === 0) {
            errors.push('A károsodott terület megrajzolása szükséges.');
        }

        if (damagePlot.sampling.length === 0) {
            errors.push('Mintavétel szükséges.');
        }

        damagePlot.sampling.forEach((s) => {
            if (!s.yield) {
                errors.push(`A ${s.sequenceId}. mintavételi pont hozamadata hiányzik.`);
            }
        });

        if (errors.length > 0) {
            showModal(MODAL_TYPE.STATUS, {
                title: 'A hozamszámítás nem lehetséges',
                messages: errors,
                onClose: () => showModal(MODAL_TYPE.NONE),
            });
            return;
        }

        setCalculating(true);

        const response = await yieldCalculationService.startCalculation(damagePlot.id);

        if (response.err) {
            showModal(MODAL_TYPE.STATUS, {
                title: 'Hozamszámítás sikertelen',
                messages: [response.err],
                onClose: () => showModal(MODAL_TYPE.NONE),
            });
            setCalculating(false);
            return;
        }

        showModal(MODAL_TYPE.STATUS, {
            title: 'Sikeres hozamszámítás',
            messages: [`A kiszámított hozam: ${response.calculatedYield} t/ha.`],
            onClose: () => showModal(MODAL_TYPE.NONE),
        });

        setCalculating(false);
        setCalculatedYield(response.calculatedYield);
    };

    if (!damage || !damagePlot) {
        return null;
    }

    return (
        <List>
            <ListItem>
                <div className={classes.layout}>
                    {meparBorderIsMissing && (
                        <div className={classes.row}>
                            <Typography className={classes.redLabel}>
                                A MePAR körvonal hiányzik!
                            </Typography>
                        </div>
                    )}
                    <div className={classes.row}>
                        <Typography className={classes.label}>MePAR kód:</Typography>
                        <Typography className={classes.value}>
                            <Link
                                style={{ ...(calculating && { pointerEvents: 'none' }) }}
                                className={classes.value}
                                to={ROUTES.BLOCK(damagePlot.meparCode)}
                            >
                                {damagePlot.meparCode}
                            </Link>
                        </Typography>
                    </div>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Kárszám:</Typography>
                        <Typography className={classes.value}>
                            <Link
                                style={{ ...(calculating && { pointerEvents: 'none' }) }}
                                className={classes.value}
                                to={ROUTES.DAMAGE(damage.damageNumber)}
                            >
                                {damage.damageNumber}
                            </Link>
                        </Typography>
                    </div>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Kár dátuma:</Typography>
                        <Typography className={classes.value}>
                            {moment(damage.damageDate).format('YYYY. MM. DD.')}
                        </Typography>
                    </div>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Kártípus:</Typography>
                        <Typography className={classes.value}>{damage.dangerType}</Typography>
                    </div>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Növény:</Typography>
                        <Typography className={classes.value}>{damagePlot.plantCode}</Typography>
                    </div>
                    <div className={classes.row}>
                        <Typography className={classes.label}>MePAR terület:</Typography>
                        <Typography className={classes.value}>
                            {`${roundHectareArea(damagePlot.mepar.geomHa)} ha`}
                        </Typography>
                    </div>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Tábla terület:</Typography>
                        <Typography
                            className={classNames(
                                classes.value,
                                damagePlot.tableGeometryHa ? null : classes.highlightedValue
                            )}
                        >
                            {damagePlot.tableGeometryHa
                                ? `${roundHectareArea(damagePlot.tableGeometryHa)} ha`
                                : 'Rajzolás szükséges!'}
                        </Typography>
                    </div>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Kár. terület:</Typography>
                        <Typography className={classes.value}>
                            {`${damagePlot.damagedArea} ha`}
                        </Typography>
                    </div>
                    {calculatedYield && (
                        <div className={classes.row}>
                            <Typography className={classes.label}>Számított hozam:</Typography>
                            <Typography className={classes.value}>
                                {`${calculatedYield} t/ha`}
                            </Typography>
                        </div>
                    )}
                </div>
            </ListItem>

            {calculating ? (
                <ListItem className={classes.loader}>
                    <Typography className={classes.progress}>
                        Hozamszámítás folyamatban...
                    </Typography>
                    <Typography className={classes.progress}>
                        (Akár 1 percig is eltarthat.)
                    </Typography>
                    <CircularProgress />
                </ListItem>
            ) : (
                <List>
                    {meparBorderIsMissing ||
                        (!zonesPresent && (
                            <ListItem>
                                <Typography className={classes.error}>
                                    Hozamszámításhoz táblahatárrajzolás, zónakijelölés és mintavétel
                                    szükséges.
                                </Typography>
                            </ListItem>
                        ))}
                    <ListItem>
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            disabled={meparBorderIsMissing || !zonesPresent}
                            className={classes.button}
                            onClick={onCalculateYield()}
                        >
                            {!calculatedYield ? `Hozamszámítás` : `Hozam újraszámolása`}
                        </Button>
                    </ListItem>

                    {!meparBorderIsMissing && (
                        <ListItem>
                            <Button
                                variant="contained"
                                color="default"
                                size="small"
                                className={classes.button}
                                onClick={onShowDrawings(damagePlot)}
                            >
                                {`Táblahatár / Mérés`}
                            </Button>
                        </ListItem>
                    )}
                    {!meparBorderIsMissing && damagePlot.tableGeometryHa && (
                        <ListItem>
                            <Button
                                variant="contained"
                                color="default"
                                size="small"
                                className={classes.button}
                                onClick={onShowSamplings(damagePlot)}
                            >
                                {`Mintavétel`}
                            </Button>
                        </ListItem>
                    )}
                    <ListItem>
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            className={classes.button}
                            onClick={onShowRadar(damagePlot)}
                        >
                            {`Időjárási kompozit`}
                        </Button>
                    </ListItem>
                    {productTypeMap[PRODUCTS.TYPES.CHRONOLOGY] && (
                        <ListItem>
                            <Button
                                variant="contained"
                                color="default"
                                size="small"
                                className={classes.button}
                                onClick={onShowProduct(
                                    damagePlot,
                                    productTypeMap[PRODUCTS.TYPES.CHRONOLOGY][0]
                                )}
                            >
                                {`Kronológia`}
                            </Button>
                        </ListItem>
                    )}
                    {productTypeMap[PRODUCTS.TYPES.CHANGE_MAP] && (
                        <ListItem>
                            <Button
                                variant="contained"
                                color="default"
                                size="small"
                                className={classes.button}
                                onClick={onShowProduct(
                                    damagePlot,
                                    productTypeMap[PRODUCTS.TYPES.CHANGE_MAP][0]
                                )}
                            >
                                {`Változás térkép`}
                            </Button>
                        </ListItem>
                    )}
                    {productTypeMap[PRODUCTS.TYPES.DAMAGE_MAP] && (
                        <ListItem>
                            <Button
                                variant="contained"
                                color="default"
                                size="small"
                                className={classes.button}
                                onClick={onShowProduct(
                                    damagePlot,
                                    productTypeMap[PRODUCTS.TYPES.DAMAGE_MAP][0]
                                )}
                            >
                                {`Káros - nem káros térkép`}
                            </Button>
                        </ListItem>
                    )}
                    {productTypeMap[PRODUCTS.TYPES.HETEROGENITY_MAP] && (
                        <ListItem>
                            <Button
                                variant="contained"
                                color="default"
                                size="small"
                                className={classes.button}
                                onClick={onShowProduct(
                                    damagePlot,
                                    productTypeMap[PRODUCTS.TYPES.HETEROGENITY_MAP][0]
                                )}
                            >
                                {`Táblamonitoring`}
                            </Button>
                        </ListItem>
                    )}
                </List>
            )}
        </List>
    );
}

export default withStyles(styles)(DamagePlotPanel);
