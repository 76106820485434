import React from 'react';
import { render } from 'react-dom';
import { Select } from 'ol/interaction';
import { singleClick } from 'ol/events/condition';
import { Fill, Stroke, Style, Text } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { LAYERS } from 'shared';
import { YIELDS } from 'shared/src/constants/yields';
import { layerService } from '../../services';
import { calcColor, hexToRGB, invertColor } from '../../helpers/colors';
import YieldsLegend from './YieldsLegend';

export default class YieldsLayer {
    map = null;
    layer = null;
    plant = null;
    table = null;
    type = null;
    max = null;
    source = null;
    select = null;
    legend = null;
    deselect = true;

    constructor(map) {
        this.map = map.map;
        this.init();
    }

    // Sets a style for a feature set
    setStyle(color, yieldData, resolution, select) {
        // Check if feature is the selected one
        const selected = this.select
            ? this.select
                  .getFeatures()
                  .getArray()
                  .map((f) => f.get('yieldData').id)
                  .includes(yieldData.id)
            : false;

        // If the feature is not the selected one or if this is the selection feature set
        // create the style and return it
        if (!selected || select) {
            const yieldAmount = yieldData.yield.toString();
            const label = select
                ? resolution < 180
                    ? yieldData.nuts4Name
                        ? `${yieldData.nuts4Name} KT: ${yieldAmount}`
                        : `${yieldData.meparCode} blokk: ${yieldAmount}`
                    : yieldAmount
                : !yieldData.nuts4Name
                ? resolution < 3.5
                    ? `${yieldData.meparCode} blokk: ${yieldAmount}`
                    : yieldAmount
                : resolution < 180
                ? `${yieldData.nuts4Name} KT: ${yieldAmount}`
                : yieldAmount;
            return new Style({
                stroke: new Stroke({
                    color: invertColor(color),
                    width: select ? 4 : 1,
                }),
                fill: new Fill({
                    color: `rgba(${hexToRGB(color).string}, ${select ? '0.3' : '0.6'})`,
                }),
                text: new Text({
                    font: '14px sans-serif',
                    overflow: true,
                    placement: 'center',
                    fill: new Fill({
                        color: 'white',
                    }),
                    text: label,
                }),
            });
        }
        return null;
    }

    async setMax(table) {
        // Run when the table changed
        if (table !== this.table) {
            // Set the active table
            this.table = table;

            // Get the greatest value from dataset for creating color scale
            const { max } = await layerService.getMaxValue(
                this.plant,
                this.table,
                this.type,
                'yield'
            );

            this.max = max;

            // Set the legend based on max value
            if (this.legend) {
                render(
                    <YieldsLegend
                        max={this.max}
                        table={YIELDS.SCALES[this.table]}
                        type={YIELDS.TYPES[this.type]}
                    />,
                    this.legend
                );
            }
        }
    }

    init() {
        // Define the source for the layer
        this.layerSource = new VectorSource({
            loader: async (extent, resolution) => {
                const extentPolygon = fromExtent(extent);

                // Get polygons from the current extent
                const response = await layerService.getYields(
                    extentPolygon,
                    this.plant,
                    resolution > 30 ? 'Nuts4' : 'Mepar',
                    this.type
                );

                const loaded = this.layerSource.getFeatures().map((f) => f.get('yieldData').id);

                // Add polygons to the layersource
                response.forEach((yieldData) => {
                    const yieldPolygon = JSON.parse(yieldData.yieldPolygon);
                    if (!loaded.includes(yieldData.id)) {
                        const feature = new Feature({
                            geometry:
                                yieldPolygon.type === 'Polygon'
                                    ? new Polygon(yieldPolygon.coordinates)
                                    : new MultiPolygon(yieldPolygon.coordinates),
                            yieldData,
                            color: calcColor(yieldData.yield, this.max),
                        });
                        this.layerSource.addFeature(feature);
                    }
                });
            },
            strategy: (extent, resolution) => {
                const table = resolution > 30 ? 'Nuts4' : 'Mepar';
                if (table !== this.table) {
                    this.layerSource.clear();
                }
                return [extent];
            },
        });

        // Define the layer
        this.layer = new VectorLayer({
            title: 'Hozamtérkép',
            type: LAYERS.TYPES.YIELD,
            visible: false,
            renderMode: 'vector',
            declutter: true,
            source: this.layerSource,
            style: (feature, resolution) =>
                this.setStyle(feature.get('color'), feature.get('yieldData'), resolution, false),
        });

        // Create interaction for selection
        this.select = new Select({
            condition: singleClick,
            layers: [this.layer],
            style: (feature, resolution) =>
                this.setStyle('#ffffff', feature.get('yieldData'), resolution, true),
        });

        // Subscribe to selection events
        this.select.on('select', (evt) => {
            const [feature] = evt.selected;

            if (feature) {
                const yieldData = feature.get('yieldData');
                const extent = feature.getGeometry().getExtent();

                // Zoom to the selected feature
                this.map.getView().fit(extent, { size: this.map.getSize(), duration: 1000 });

                // Since selection triggers a move event we have to distinguish it from user zoom events
                this.deselect = false;

                // Rerender the legend with data of the selected feature
                if (this.legend && this.table && yieldData) {
                    render(
                        <YieldsLegend
                            max={this.max}
                            table={YIELDS.SCALES[this.table]}
                            type={YIELDS.TYPES[this.type]}
                            yieldData={yieldData}
                        />,
                        this.legend
                    );
                }
            }
        });

        // Subscribe for end of zoom (move) events
        this.map.on('moveend', async (e) => {
            // Get the current resolution to determine which datasource should we use
            const res = this.map.getView().getResolution();

            const table = res > 30 ? 'Nuts4' : 'Mepar';

            // Set Maximum value if table changed
            await this.setMax(table);

            // If zoom event is triggered by the user, remove the selection
            // and rerender legend without selection data
            if (this.deselect) {
                this.select.getFeatures().clear();
                if (this.legend && this.table) {
                    render(
                        <YieldsLegend
                            max={this.max}
                            table={YIELDS.SCALES[this.table]}
                            type={YIELDS.TYPES[this.type]}
                        />,
                        this.legend
                    );
                }
            }

            // We can set the distinguisher back to the initial value
            this.deselect = true;
        });
    }

    getLayer() {
        return this.layer;
    }

    async update(layer) {
        // Initiate the layer on every page reload
        if (layer.plant && layer.yieldType) {
            this.layer.setVisible(layer.visible);
            this.plant = layer.plant;
            this.type = layer.yieldType;
            this.table = null;

            // Remove all features from layerSource
            this.layerSource.clear();

            // Get the container for the legend
            this.legend = document.getElementById('yieldsLegend');

            // Add selection interaction to the map
            this.map.addInteraction(this.select);

            // Set Maximum value
            await this.setMax('Nuts4');
            
        }
    }
}
