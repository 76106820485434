import moment from 'moment';
import { Circle, Fill, Stroke, Style, Text } from 'ol/style';
import {Draw, Modify, Select} from 'ol/interaction';
import { singleClick } from 'ol/events/condition';
import CircleStyle from 'ol/style/Circle';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { LAYERS } from 'shared';
import { drawingActions, mapActions } from '../../state/actions';

moment.locale('hu');

export default class SamplingLayer {
    map = null;
    source = null;
    draw = null;
    modify = null;
    pointerSelect = null;
    pointerRemove = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.source = new VectorSource();

        this.layer = new VectorLayer({
            title: 'Mintavételi terv',
            type: LAYERS.TYPES.SAMPLING,
            visible: false,
            source: this.source,
            displayInLayerSwitcher: false,
            style: (feature) => {
                const samplingItem = feature.get('samplingItem');
                return new Style({
                    image: new Circle({
                        radius: 7,
                        fill: new Fill({ color: 'black' }),
                        stroke: new Stroke({
                            color: samplingItem.isGenerated ? [255, 0, 0] : [0, 255, 0],
                            width: 2,
                        }),
                    }),
                    text: new Text({
                        font: '14px sans-serif',
                        overflow: true,
                        placement: 'above',
                        fill: new Fill({
                            color: 'white',
                        }),
                        text: `${samplingItem.sequenceId}`,
                    }),
                });
            },
        });

        this.modify = new Modify({
            source: this.source,
            layers: [this.layer],
        });

        this.draw = new Draw({
            source: this.source,
            type: 'Point',
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.5)',
                    lineDash: [10, 10],
                    width: 2,
                }),
                image: new CircleStyle({
                    radius: 5,
                    stroke: new Stroke({
                        color: 'rgba(0, 0, 0, 0.7)',
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.2)',
                    }),
                }),
            }),

            layers: [this.layer],
        });

        this.draw.on('drawend', (evt) => {
            const damagePlot = this.layer.get('damagePlot');
            const sampling = damagePlot.sampling;
            const polygon = {
                type: 'Point',
                coordinates: evt.feature.getGeometry().getCoordinates(),
            };

            const lastId = sampling.reduce((max, { sequenceId })=> Math.max(max, sequenceId), 0);

            const samplingItem = {
                damagePlotId: damagePlot.id,
                sequenceId: lastId + 1,
                isGenerated: false,
                isUsed: true,
                polygon: JSON.stringify(polygon),
                comment: '',
                yield: null,
                newPoint: true,
            };
            sampling.push(samplingItem);

            evt.feature.getGeometry().on('change', (changeEvent) => {
                polygon.coordinates = changeEvent.target.getCoordinates();
                samplingItem.polygon = JSON.stringify(polygon);
            });

            evt.feature.set('samplingItem', samplingItem);
            this.map.props.dispatch(drawingActions.setDrawing(samplingItem));
        });

        this.pointerSelect = new Select({
            condition: singleClick,
            layers: [this.layer],
        });

        this.pointerSelect.on('select', (evt) => {
            const [feature] = evt.selected;

            if (feature) {
                const samplingItem = feature.get('samplingItem');
                this.map.props.dispatch(drawingActions.setDrawing(samplingItem));
            }
        });

        this.pointerRemove = new Select({
            condition: singleClick,
            layers: [this.layer],
        });

        this.pointerRemove.on('select', (evt) => {
            const [feature] = evt.selected;

            if (feature) {
                const samplingItem = feature.get('samplingItem');
                samplingItem.removable = true;
                this.map.props.dispatch(drawingActions.setDrawing(samplingItem));
            }
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        if (
            // layer.damagePlot !== this.layer.get('damagePlot') &&
            layer.damagePlot &&
            layer.damagePlot.sampling
        ) {
            const damagePlot = layer.damagePlot;
            const sampling = damagePlot.sampling;
            this.source.clear();

            sampling.forEach((samplingItem) => {
                const polygon = JSON.parse(samplingItem.polygon);
                const feature = new Feature({
                    geometry: new Point(polygon.coordinates),
                    samplingItem,
                });

                feature.getGeometry().on('change', (evt) => {
                    polygon.coordinates = evt.target.getCoordinates();
                    samplingItem.polygon = JSON.stringify(polygon);
                });

                this.source.addFeature(feature);
            });

            this.layer.set('damagePlot', damagePlot);
        }

        this.layer.setVisible(layer.visible);

        if (layer.setPointData) {
            this.map.map.addInteraction(this.pointerSelect);
        } else {
            this.map.map.removeInteraction(this.pointerSelect);
        }

        if (layer.setPosition) {
            this.map.map.addInteraction(this.modify);
        } else {
            this.map.map.removeInteraction(this.modify);
        }

        if (layer.newPoint) {
            this.map.map.addInteraction(this.draw);
        } else {
            this.map.map.removeInteraction(this.draw);
        }

        if (layer.removePoint) {
            this.map.map.addInteraction(this.pointerRemove);
        } else {
            this.map.map.removeInteraction(this.pointerRemove);
        }
    }
}
